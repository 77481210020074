<template>
  <v-card>
    <v-system-bar color="primary darken-3"></v-system-bar>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <a href="/" class="logo-link">
          <img
            class="fade-in"
            src="/img/clutcharena.svg"
            alt="CS:GO Logo"
            style="height: 40px; padding-top: 5px; padding-right: 15px"
          />
        </a>
      </v-toolbar-title>

      <!-- Ícone do Discord -->
      <span
        v-if="!isMobile"
        class="icon-link discord-icon"
        @click="redirectTo('/discord')"
      >
        <img
          src="/img/icons/discord-mark-white.svg"
          alt="Discord"
          class="icon-image"
        />
      </span>

      <!-- Ícone do WhatsApp -->
      <span
        v-if="!isMobile"
        class="icon-link whatsapp-icon"
        @click="redirectTo('/whatsapp')"
      >
        <v-icon class="icon">mdi-whatsapp</v-icon>
      </span>

      <v-spacer></v-spacer>

      <!-- Botão de Login -->
      <v-btn
        rounded
        color="darken-2"
        :href="apiUrl + '/auth/steam'"
        v-if="user.id == null"
        class="login-btn"
      >
        <v-icon
          :large="isMobile"
          :x-large="isMobile"
          class="text-center"
          style="width: 100%;"
        >
          mdi-steam
        </v-icon>
        <span class="login-text" v-show="!isMobile">Login via Steam</span>
      </v-btn>

      <!-- Menu do Usuário -->
      <v-menu
        v-if="user.id !== null"
        bottom
        offset-y
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="user-menu">
            <v-btn v-on="on" v-bind="attrs" class="vip-badge" depressed>
              <div class="vip-user-info">
                <span class="vip-text">{{ truncatedName }}</span>
                <span v-if="user.vip?.isVIP" class="vip-subtext-vip">
                  <v-icon dense small>mdi-crown</v-icon> VIP
                </span>
                <span v-else class="vip-subtext">MEMBRO</span>
              </div>
              <v-avatar size="32">
                <img :src="user.small_image" />
              </v-avatar>
            </v-btn>
          </div>
        </template>

        <v-list dense>
          <v-list-item :to="'/user/' + user.id">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Navbar.Profile") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="apiUrl + '/logout'">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Navbar.Logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary fixed app>
      <v-list class="user-info-list">
        <v-list-item v-if="user.id != null" class="user-info">
          <!-- Avatar do usuário -->
          <v-list-item-avatar class="user-avatar">
            <v-img :src="user.medium_image"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <!-- Nome do usuário ao lado do avatar -->
            <v-list-item-title class="user-name">
              {{ truncatedName }}
            </v-list-item-title>
          </v-list-item-content>
          <v-chip v-if="user.vip?.isVIP" class="vip-chip vip-active">
            <v-icon left style="color: rgba(255, 153, 0, 0.8);" small>mdi-crown</v-icon>VIP
          </v-chip>
          <v-chip v-else class="vip-chip vip-inactive">
            MEMBRO
          </v-chip>
        </v-list-item>
        <div class="user-vip-status"></div>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item index="Home" :to="'/'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.Home") }}</v-list-item-title>
          </v-list-item>

          <v-list-item index="Inventory" :to="'/inventory'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-knife-military</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inventário</v-list-item-title>
          </v-list-item>

          <v-list-item index="BuyVip" :to="'/plans'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-crown</v-icon>
            </v-list-item-icon>
            <v-list-item-title>VIP</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/ranking'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-clipboard-flow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("Navbar.PlayerLeader") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item index="Matches" :to="'/matches'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-gamepad-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AllMatches") }}</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item
            v-if="user.id != null"
            index="mymatches"
            :to="'/mymatches'"
          >
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-gamepad-down</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MyMatches") }}</v-list-item-title>
          </v-list-item> -->

          <v-list-item
            v-if="
              user.id != null && (user.admin === 1 || user.super_admin === 1)
            "
            index="match_create"
            :to="'/match/create'"
          >
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-creation</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t("Navbar.CreateMatch")
            }}</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item v-if="user.id != null" :to="'/myteams'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-spider-web</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MyTeams") }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/teams'" exact>
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-bulletin-board</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AllTeams") }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="user.id != null" :to="'/teams/create'" exact>
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-bullseye-arrow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.CreateTeam") }}</v-list-item-title>
          </v-list-item> -->

          <v-list-item
            v-if="
              user.id != null && (user.admin === 1 || user.super_admin === 1)
            "
            :to="'/myservers'"
          >
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-server</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MyServers") }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="
              user.id != null && (user.admin === 1 || user.super_admin === 1)
            "
            @click="newDialog = true"
          >
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-server-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AddServer") }}</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item :to="'/seasons'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AllSeasons") }}</v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item
            v-if="
              user.id != null && (user.admin === 1 || user.super_admin === 1)
            "
            :to="'/myseasons'"
          >
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-seal</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MySeasons") }}</v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <ServerDialog
      v-model="newDialog"
      :serverInfo="{}"
      :title="$t('MyServers.New')"
    />
    <LoginDialog v-model="loginDialog" :title="$t('Login.title')" />
  </v-card>
</template>
<script>
import ServerDialog from "./ServerDialog";
import LoginDialog from "./LoginDialog";
import { mapState } from "vuex";

export default {
  name: "Navbar",
  components: {
    ServerDialog,
    LoginDialog
  },
  computed: {
    ...mapState(["user"]),
    truncatedName() {
      return this.user.name.length > 10
        ? this.user.name.substring(0, 10) + "..."
        : this.user.name;
    }
  },
  data() {
    return {
      isMobile: false,
      drawer: false,
      group: null,
      newDialog: false,
      loginDialog: false,
      apiUrl: process.env?.VUE_APP_G5V_API_URL || "/api"
    };
  },
  mounted() {
    this.updateMobileView();
    window.addEventListener("resize", this.updateMobileView);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateMobileView);
  },
  methods: {
    updateMobileView() {
      this.isMobile = window.innerWidth <= 600; // Define 600px como o limite para mobile
    },
    redirectTo(route) {
      this.$router.push(route);
    }
  },
  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>

<style scoped>
.icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px; /* Espaçamento entre ícones */
  cursor: pointer; /* Indica que é clicável */
  transition: transform 0.2s ease-in-out;
}

.icon-link:hover {
  transform: scale(1.1); /* Efeito de "zoom" ao passar o mouse */
}

.icon-image {
  height: 20px; /* Tamanho uniforme para imagens */
  width: 20px;
  filter: brightness(0.6); /* Cor cinza escura */
  transition: filter 0.2s ease-in-out;
}

.icon {
  font-size: 20px; /* Tamanho consistente para ícones */
  color: #979696; /* Cinza escuro */
  transition: color 0.2s ease-in-out;
}

.icon:hover {
  color: #a6a6a6; /* Clareia no hover */
}

.v-application .primary--text {
  color: #a6a6a6 !important;
  caret-color: #424242 !important;
}
.login-btn {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1000;
  background-color: #333; /* Preto claro */
  color: white; /* Cor da fonte */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-text {
  text-transform: none; /* Mantém Maiúsculo e Minúsculo */
}

.logo-link {
  display: inline-block; /* Faz o link ocupar apenas o espaço necessário */
  cursor: pointer; /* Muda o cursor para a mão ao passar sobre o link */
  text-decoration: none; /* Remove o sublinhado do link */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-out;
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 8px;
}

.vip-badge {
  display: flex;
  align-items: right;
  justify-content: space-between; /* Espaçamento uniforme */
  background-color: black;
  border: 1px solid rgb(255, 153, 0);
  border-radius: 8px;
  font-weight: bold;
  color: white;
  height: 45px !important;
  padding: 5px 10px;
  min-width: 130px; /* Garante que o botão tenha um tamanho fixo */
}

.vip-text {
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
  white-space: nowrap; /* Evita quebra de linha no nome */
  margin-right: 14px;
}

.vip-subtext-vip,
.vip-subtext {
  font-style: italic;
  font-size: 0.7rem;
  margin-right: 14px;
}

.vip-subtext-vip {
  color: rgba(255, 153, 0, 0.8);
}

.vip-subtext {
  color: rgba(255, 94, 0, 0.801);
}

.vip-badge v-avatar {
  margin-left: auto;
}

.user-info-list {
  background: #121212; /* Fundo escuro do painel */
  padding: 10px;
  border-radius: 8px;
}

.user-info {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
}

.user-avatar {
  border: 2px solid rgba(255, 153, 0, 0.8);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.user-name {
  font-weight: bold;
  font-size: 1rem;
  color: white;
  margin-bottom: 4px;
}

.user-vip-status {
  display: flex;
  margin-top: 4px;
}

.vip-chip {
  font-weight: bold;
  margin-left: 10px;
}

.vip-active {
  color: rgba(255, 153, 0, 0.8);
  font-style: italic;
  font-size: 0.7rem;
}

.vip-inactive {
  color: rgba(255, 94, 0, 0.801);
  font-style: italic;
  font-size: 0.6rem;
}

.vip-user-info {
  display: flex;
  flex-direction: column; /* Coloca nome e status um abaixo do outro */
  align-items: center; /* Centraliza horizontalmente */
  text-align: center; /* Garante que o texto fique bem centralizado */
  flex-grow: 1; /* Permite que o nome e status ocupem mais espaço */
}

.v-avatar {
  flex-shrink: 0; /* Evita que o avatar seja reduzido pelo flexbox */
}
</style>
