<template>
  <div class="title-bar" :style="{ backgroundImage: `url(${currentGif})` }" @click="openWebsite">
    <div class="title-overlay">
      <div class="title-content">
        <h1 :key="animationKey" class="slide-in">{{ subTitleNav }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      gifs: [
        { url: "/img/backgrounds/clutch.gif", link: "https://www.clutcharena.com.br/plans" },
        { url: "/img/backgrounds/suaskin.gif", link: "https://www.suaskin.com.br" }
      ],
      currentIndex: 0,
      intervalId: null
    };
  },
  computed: {
    ...mapState(["subTitleNav"]),
    currentGif() {
      return this.gifs[this.currentIndex].url;
    },
    currentLink() {
      return this.gifs[this.currentIndex].link;
    },
    animationKey() {
      return this.subTitleNav + Date.now();
    }
  },
  methods: {
    openWebsite() {
      window.open(this.currentLink, "_blank");
    },
    toggleGif() {
      this.currentIndex = (this.currentIndex + 1) % this.gifs.length;
    },
    startBannerRotation() {
      this.intervalId = setInterval(() => {
        this.toggleGif();
      }, 15000); // Troca a cada 15 segundos
    }
  },
  mounted() {
    this.startBannerRotation();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
</script>

<style>
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
}

.title-bar {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 100%;
  height: 236px;
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-image 1s ease-in-out;
}

.title-overlay {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0 20px 20px 70px;
}

.title-content {
  text-align: left;
}

.title-content h1 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
</style>
