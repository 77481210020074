import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      admin: false,
      steam_id: "",
      id: null,
      super_admin: false,
      name: "",
      small_image: "",
      medium_image: "",
      large_image: "",
      vip: null
    },
    subTitleNav: ""
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setSubTitleNav(state, subTitleNav) {
      state.subTitleNav = subTitleNav;
    }
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const res = await axios.get(
          `${process.env?.VUE_APP_G5V_API_URL || "/api"}/isLoggedIn`
        );
        const user =
          res.data === false
            ? {
                admin: false,
                steam_id: "",
                id: null,
                super_admin: false,
                name: "",
                small_image: "",
                medium_image: "",
                large_image: "",
                vip: null
              }
            : res.data;
        console.log("User fetched:", user);
        commit("setUser", user);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    },
    updateSubTitleNav({ commit }, subTitleNav) {
      commit("setSubTitleNav", subTitleNav);
    }
  },
  getters: {
    isAuthenticated: state => !!state.user.id,
    isAdmin: state => state.user.admin,
    isSuperAdmin: state => state.user.super_admin,
    isVip: state => state.user.vip?.isVIP
  },
  modules: {}
});
